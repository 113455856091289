<template>
  <div>
    <div class="table-page-search-wrapper" v-if="true">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="5" :sm="24">
            <a-form-item label="仓库">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-model="queryParam.warehouse_id"
                placeholder="请输入仓库"
                :default-active-first-option="false"
                @search="this.warehouseSearch"
                @change="handleWarehouseChange"
              >
                <a-select-option v-for="d in warehouseList" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
            </span>
          </a-col>
          <a-col :md="1" :sm="24" v-if="false">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
            </span>
          </a-col>
          <a-col :md="2" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="handleArchived">批量存档</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator" v-if="false">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :rowSelection="options.rowSelection"
      :alert="options.alert"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 90px; max-width: 200px">
        {{ (text / 1).toFixed(2) | numberFormat }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleAttachment(record)">附件</a>
          <a-divider type="vertical" />
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelete(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      v-if="visible"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <archived-form
      ref="archivedModal"
      :visible="archived_visible"
      @cancel="handleArchivedCancel"
      @ok="handleArchivedOK"
    />
  </div>
</template>

<script>
import { STable } from '@/components'
import ArchivedForm from '../modules/ArchivedForm'
import {
  inventory_adjustment_list,
  inventory_adjustment_create,
  inventory_adjustment_delete,
  inventory_adjustment_archived
} from '@/api/trade_inventory_adjustment'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'
import debounce from 'lodash/debounce'
import { warehouse_list } from '@/api/trade_warehouse'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm,
    ArchivedForm
  },
  data () {
    this.warehouseSearch = debounce(this.handleWarehouseSearch, 800)
    return {
      loading: false,
      confirmLoading: false,
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      archived_visible: false,
      mdl: {},
      warehouseList: [],
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '仓库',
          dataIndex: 'warehouse'
        },
        {
          title: '数量',
          dataIndex: 'count',
          scopedSlots: { customRender: 'money_render' },
          align: 'right',
          width: 200
        },
        {
          title: '单位',
          dataIndex: 'unit',
          align: 'center',
          width: 150,
          customRender: (text) => this.$Dictionaries.unit[text] ? this.$Dictionaries.unit[text] : '-'
        },
        {
          title: '预估人民币价格',
          dataIndex: 'unit_price_cny',
          scopedSlots: { customRender: 'money_render' },
          align: 'right',
          width: 200
        },
        {
          title: '开启审批',
          dataIndex: 'is_approval_needed',
          ellipsis: true,
          align: 'center',
          width: 150,
          customRender: (text) => text ? '是' : '否'
        },
        {
          title: '已存档',
          dataIndex: 'is_archived',
          ellipsis: true,
          align: 'center',
          width: 150,
          customRender: (text) => text ? '是' : '否'
        },
        {
          title: '审批状态',
          dataIndex: 'status',
          align: 'center',
          width: 150,
          customRender: (text, record) => record.is_approval_needed ? (this.$Dictionaries.approval_result[text] ? this.$Dictionaries.approval_result[text] : '无') : '未开启审批'
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return inventory_adjustment_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
  },
  methods: {
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleAdd () {
      const date = new Date()
      this.mdl = { code: date.getFullYear() + '' + (date.getMonth() + 1) + date.getDay() }
      this.visible = true
    },
    handleArchived () {
      if (this.selectedRowKeys.length > 0) {
        this.archived_visible = true
      } else {
        this.$message.info('选择存档记录')
      }
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleAttachment (record) {
      // this.$emit('onEdit', record)
      this.$router.push({ path: '/trade/inventory/' + this.$route.params.inventory_id + '/adjustment/' + record.id + '/attachment', query: { warehouse: record.warehouse, count: record.count } })
    },
    handleEdit (record) {
      // this.$emit('onEdit', record)
      this.$router.push({ path: '/trade/inventory/' + this.$route.params.inventory_id + '/attachment/' + record.id })
    },
    handleDelete (record) {
      inventory_adjustment_delete(record.id).then((res) => {
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values, this.$route.params.inventory_id)
            // 新增
          values.inventory_id = this.$route.params.inventory_id
          inventory_adjustment_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              if (res.code === 1000) {
                this.$refs.table.refresh()
              }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleWarehouseSearch (value) {
      warehouse_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.warehouseList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handleWarehouseChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handleWarehouseSearch(value)
      }
    },
    handleArchivedCancel () {
      this.archived_visible = false
      const form = this.$refs.archivedModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleArchivedOK () {
      const form = this.$refs.archivedModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          const data = {
            ids: this.selectedRowKeys,
            ...values
          }
          inventory_adjustment_archived(data).then(res => {
            // 刷新表格
            if (res.code === 1000) {
              this.$refs.table.refresh()
            }
          }).finally(() => {
            this.archived_visible = false
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    }
  }
}
</script>
